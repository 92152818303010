<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <active-remote-connections
    :page="Number(page)"
    :itemsPerPage="Number(itemsPerPage)"
    :search="search"
    :sortBy="Array.isArray(sortBy) ? (sortBy[0] !== undefined ? sortBy : ['created'])
    : [sortBy !== undefined ? sortBy : 'created']"
    :sortDesc="Array.isArray(sortDesc) ? sortDesc :
    [sortDesc && sortDesc.length > 0 ? sortDesc === 'true' : true]"
    :type="type"
  />
</template>
<script>
import ActiveRemoteConnections from '@/components/active-remote-connections/ActiveRemoteConnections.vue';

export default {
  components: {
    ActiveRemoteConnections,
  },
  props: ['page', 'itemsPerPage', 'search', 'sortBy', 'sortDesc', 'type'],
};
</script>
